import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-team-dynamic', () => [
		{
			// base
			[`.${prefixCls}`]: {
        // color: chartToken.$font_color,
        //
        // '&::after': {
        //   content: '""', // content不能为空，否则会被忽略
        //   position: 'absolute'
        // }

        width: '100%',
        height: '100%',
        padding: '16px 7px 16px 16px',
        background: '#ffffff',
        borderRadius: '12px',

        [`.${prefixCls}-title`]: {
          // display: 'flex',
          // alignItems: 'center',
          color: 'rgba(0, 0, 0, 0.84)',
          paddingRight: '8px',
          position: 'relative',
          ['.title-info']:{
            fontSize: '20px',
            fontWeight: '600',
          },
          ['.ec-ehr-base-card-title-text']:{
            display: 'flex',
            alignItems: 'center',
          },
          [`.${prefixCls}-title-filter`]: {
            display: 'flex',
            alignItems: 'center',
            userSelect: 'none',
            marginLeft: '16px',
            [`.${prefixCls}-time`]: {
              marginRight: '6px',
              borderRadius: '8px',
              background: '#F5F5F5',
              cursor: 'pointer',
              '.time-switch-popconfirm-content-item': {
                display: 'flex',
                padding: '5px 0px',
                position: 'relative',
                '.prev,.next': {
                  position: 'absolute',
                  top: '5px',
                  width: '20px',
                  height: '22px',
                  textAlign: 'center',
                  lineHeight: '24px',
                  zIndex: '999',
                },
                '.prev:hover, .next:hover':{
                  background: 'rgba(0, 0, 0, 0.08)',
                  borderRadius: '6px',
                  cursor: 'pointer'
                },
                '.prev': {
                  left: '4px'
                },
                '.next': {
                  right: '4px'
                },
                '.ant5-picker': {
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  '.ant5-picker-input input':{
                    textAlign: 'center',
                    width: '80px',
                    margin: '0 25px',
                    '&:hover':{
                      background: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '6px',
                      cursor: 'pointer'
                    }
                  }
                },
                '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                  boxShadow: 'none',
                }
              }
            },
            [`.${prefixCls}-scope`]: {
              marginRight: '6px',

              ['.ant5-select']: {
                ['.ant5-select-selector']: {
                  padding: '5px 6px',
                  borderRadius: '8px',
                  background: '#F5F5F5',
                  border: 'none',
                  ['.ant5-select-selection-item']: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                  }
                },
                ['.ant5-select-arrow']: {
                  right: '6px',
                  width: '14px',
                  height: '14px',
                  color: 'rgb(12, 21, 46)',
                },

                ['.ant5-select-dropdown']: {
                  width: 'auto !important',
                  padding: '4px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                  ['.ant5-select-item']: {
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['&:hover']: {
                      background: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  ['.ant5-select-item-option-selected']: {
                    color: '#FF6400',
                    background: '#FFF7F3',
                    fontWeight: '500',
                    ['&:hover']: {
                      background: '#FFF7F3'
                    }
                  }
                },
                ['&:hover ']: {
                  ['.ant5-select-selector']: {
                    background: 'rgba(0, 0, 0, 0.08)',
                  },
                  ['.ant5-select-arrow']: {
                    opacity: '1',
                  },
                },
                ['&.ant5-select-focused .ant5-select-selector']: {
                  border: 'none',
                  boxShadow: 'none'
                }
              }

            },
            [`.${prefixCls}-summary`]: {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0px',
              color: 'rgba(0, 0, 0, 0.72)',
            }
          },
          ['.title-msg']: {
            position:'absolute',
            right: '16px',
            top: '16px',
            fontSize: '14px',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.84)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            padding: '0 6px',
            color: 'rgba(0, 0, 0, 0.72)',
          },
          ['.title-msg:hover']:{
            cursor: 'pointer',
            borderRadius: '8px',
            background: 'rgba(0, 0, 0, 0.04)',
          }
        },

        ['.dynamic-list']: {
          [`.${prefixCls}-filter`]: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px',
            paddingRight: '9px',
            paddingBottom: '16px',
            userSelect: 'none',
            [`.${prefixCls}-filter-item`]: {
              display: 'flex',
              alignItems: 'center',
              ['.switch-type']: {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '4px 6px',
                borderRadius: '6px',
                background: 'rgba(0, 0, 0, 0.04)',
                ['.ant5-btn']: {
                  height: '24px',
                  padding: '0px 12px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  borderRadius: '4px',
                  borderColor: 'transparent',
                  background: 'transparent',
                  marginRight: '4px',
                  ['&:focus']: {
                    color: 'rgba(0, 0, 0, 0.6) !important',
                    borderColor: 'transparent !important',
                    background: 'transparent !important',
                  },
                  ['&::after, & div']: {
                    display: 'none',
                  },
                  ['&.active']: {
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.84) !important',
                    borderColor: '#FFFFFF !important',
                    background: '#FFFFFF !important',
                    boxShadow: '0px 2px 6px 0px rgba(78, 98, 130, 0.02)',
                  }
                },
                ['.ant5-btn:hover']: {
                  background: 'rgba(0, 0, 0, 0.08)',
                }
              },
              ['.switch-dynamic']: {
                marginLeft:'8px',
                ['.ant5-select']: {
                  ['.ant5-select-selector']: {
                    padding: '5px 6px',
                    borderRadius: '8px',
                    background: '#F5F5F5',
                    border: 'none',
                    ['.ant5-select-selection-item']: {
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '22px',
                      letterSpacing: 0,
                      color: 'rgba(0, 0, 0, 0.84)',
                    }
                  },
                  ['.ant5-select-arrow']: {
                    right: '6px',
                    width: '14px',
                    height: '14px',
                    color: 'rgb(12, 21, 46)',
                  },

                  ['.ant5-select-dropdown']: {
                    width: 'auto !important',
                    padding: '4px',
                    borderRadius: '8px',
                    background: '#FFFFFF',
                    boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                    ['.ant5-select-item']: {
                      fontSize: '14px',
                      lineHeight: '22px',
                      letterSpacing: 0,
                      color: 'rgba(0, 0, 0, 0.84)',
                      borderRadius: '4px',
                      ['&:hover']: {
                        background: 'rgba(0, 0, 0, 0.04)'
                      }
                    },
                    ['.ant5-select-item-option-selected']: {
                      color: '#FF6400',
                      background: '#FFF7F3',
                      fontWeight: '500',
                      ['&:hover']: {
                        background: '#FFF7F3'
                      }
                    }
                  },
                  ['&:hover ']: {
                    ['.ant5-select-selector']: {
                      background: '#F1F1F1',
                    },
                    ['.ant5-select-arrow']: {
                      opacity: '1',
                    },
                  },
                  ['&.ant5-select-focused .ant5-select-selector']: {
                    border: 'none',
                    boxShadow: 'none'
                  }
                }
              },

              ['.total-tip']: {
                marginLeft: '8px',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                letterSpacing: '0px',
                color: 'rgba(0, 0, 0, 0.72)',
              }
            },
          },

          ['.ant5-spin-nested-loading']: {
            height: '100%',

            ['.ant5-spin-container']: {
              height: '100%',

              [`.${prefixCls}-content`]: {
                // display: 'grid',
                // gridTemplateColumns: '1fr',
                // // gridAutoRows: '56px',
                // gap: '12px',
                height: '100%',
                paddingRight: '7px',
                overflowY: 'auto',
                boxShadow: 'inset 0px 6px 10px -8px rgba(0, 0, 0, 0.08)',
                ['&::-webkit-scrollbar']: {
                  display: 'block',
                  width: '2px',
                },
                ['&::-webkit-scrollbar-track']: {
                  background: 'transparent',
                },

                [`.${prefixCls}-content-item`]: {
                  position: 'relative',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  '-webkit-box-orient': 'vertical',
                  '-webkit-line-clamp': '2',

                  height: '68px',
                  marginBottom: '10px',
                  padding: '10px 10px 6px',
                  fontSize: '14px',
                  borderRadius: '8px',
                  background: 'rgba(245, 245, 245, 0.6)',
                  boxSizing: 'border-box',
                  border: '0.5px solid rgba(245, 245, 245, 0.6)',
                  cursor: 'pointer',
                  ['.item-date-message']:{
                    display: 'flex',
                    alignItems: 'center',
                    visibility: 'hidden',
                    position: 'absolute',
                    right: '10px',
                    bottom: '10px',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    lineHeight: '24px',
                    color: '#FF6400',
                  },
                  ['&:hover']: {
                    background: 'rgba(255, 100, 0, 0.02)',
                    border: '0.5px solid #FF6400',

                    ['.item-date']: {
                      background: '#fffcfb',
                      display: 'none',
                      fontSize: '14px',
                    },
                    ['.item-date-message']:{
                      visibility: "visible",
                    },
                  },

                  ['.source-tag']: {
                    marginRight: '6px',
                    padding: '0px 4px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                    // border: '0.5px solid rgba(0, 179, 101, 0.5)',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: 0,
                    color: '#00B365',
                  },
                  ['.green']:{
                    color: 'rgba(0, 150, 89, 0.8)',
                    background: 'linear-gradient(0deg, rgba(0, 150, 89, 0.1), rgba(0, 150, 89, 0.1)), #FFFFFF'
                  },
                  ['.red']:{
                    color: '#F5483B',
                    background: 'linear-gradient(0deg, rgba(245, 72, 59, 0.1), rgba(245, 72, 59, 0.1)), #FFFFFF'
                  },
                  ['.blue']:{
                    color: 'rgba(10, 112, 245, 0.8)',
                    background: 'linear-gradient(0deg, rgba(10, 112, 245, 0.1), rgba(10, 112, 245, 0.1)), #FFFFFF'
                  },
                  ['.content']: {
                    width: '369px',
                  },
                  ['.content-text']: {
                    fontSize: '14px',
                    lineHeight: '22px',
                    wordBreak: 'break-all',
                    marginBottom: '0',
                    color: 'rgba(0, 0, 0, 0.72)',
                  },
                  ['.item-date']: {
                    position: 'absolute',
                    right: '9px',
                    bottom: '10px',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'right',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.36)',
                    background: '#f9f9f9',
                  },
                  ['.shadow-dom']: {
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'right',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                },

                ['.loading-tip']: {
                  height: '17px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: 'normal',
                  textAlign: 'center',
                  letterSpacing: 0,
                  color: 'rgba(0, 0, 0, 0.6)',
                }
              },

              [`.${prefixCls}-content-null`]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                ['img']: {
                  width: '120px'
                },
                ['.text-item-1']: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: '14px',
                  letterSpacing: 0,
                  color: '#2D3040',
                },
                ['.text-item-2']: {
                  marginTop: '6px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: '14px',
                  letterSpacing: 0,
                  color: '#ABACB3',
                },
              },

              [`.${prefixCls}-content-err`]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
                ['img']: {
                  width: '120px',
                },
                ['.text-item-1']: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: '14px',
                  letterSpacing: 0,
                  color: '#2D3040',
                },
                // ['.text-item-2']: {
                //   marginTop: '6px',
                //   fontSize: '12px',
                //   fontWeight: 'normal',
                //   lineHeight: '14px',
                //   letterSpacing: 0,
                //   color: '#ABACB3',
                // },
              },
            },
          },
        },
			},
      [`.${prefixCls}-overlay-tooltip`]: {
        maxHeight: '300px', /* 设置你期望的最大高度 */
        overflowY: 'auto', /* 允许垂直滚动 */
      },
		}
	]);
};

export default useStyle;
